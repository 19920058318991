<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      modal-class="oderDetail"
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Close"
      header-close-content=""
      ok-only
    >
      <b-container>
        <!-- <div class="text-center" v-if="isLoading">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div> -->
        <div class="d-flex flex-column justify-content-between w-100 oderDetailBody">
          <div class="oderDetailContent">
            <b-card class="section-body">
              <!-- {{ viewItems }} -->

              <div class="ticketItemContent ticketItemContentAcc">
                <div class="transaction-info">
                  <div class="transaction-info-row row d-flex">
                    <div
                      class="transaction-info-item transaction-info-item-account col-4"
                      v-for="(value, key) in editedItem"
                      :key="key"
                    >
                      <div v-if="key !== 'metadata'" class="item-title text-capitalize">
                        {{ key.replaceAll('_', ' ') }}
                      </div>
                      <div class="item-value" v-if="key === 'metadata'"></div>
                      <div class="item-value" v-else>
                        {{ value ? value : 'null' }}
                      </div>
                    </div>
                  </div>
                  <div class="text-uppercase metadata">Metadata</div>
                  <div class="transaction-info-row row d-flex">
                    <div
                      class="transaction-info-item transaction-info-item-account col-4"
                      v-for="(value, key) in receiver_info"
                      :key="key"
                    >
                      <div class="item-title text-capitalize">
                        {{ key.replaceAll('_', ' ') }}
                      </div>
                      <div class="item-value">
                        {{ value ? value : 'null' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </b-container>
    </b-modal>
    <b-modal
      v-model="dialogUpdate"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      :ok-disabled="isDisableBtn"
      header-close-content=""
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- status -->
          <b-col cols="12">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select
                v-model="filter_status"
                :options="statusList"
                name="Status"
                data-vv-as="Status"
                :class="{ input: true, 'is-danger': errors.has('Status') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Status--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">--Please select Status--</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('Status')" class="is-danger-text position-relative">{{
                errors.first('Status')
              }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Accounts List</h4>
      </b-col>

      <!-- Filter Phone -->
      <b-col cols="4">
        <b-form-group id="input-phone-filter" label="Phone" label-for="phone-filter">
          <b-form-input
            id="phone-filter"
            v-model="filter_phone"
            type="text"
            placeholder="Search phone..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- Filter Active -->
      <b-col cols="2">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>

      <!-- <b-col cols="4" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem"
          >Add</b-button
        >
      </b-col> -->
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(status)="item" :v-if="item.value == 1">
        <b-badge class="mr-1" v-if="item.value == 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value == 0" variant="warning">Inactive</b-badge>
        <b-badge class="mr-1" v-else variant="danger">Pending</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="viewItem(item.item)">mdi-eye</v-icon>
        <v-icon class="text-success" @click="editItem(item.item)">mdi-pencil</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
import { formatDateV2 } from '@/utils/time.js';
export default {
  name: 'account',
  components: {},
  data() {
    return {
      search: '',
      isBusy: false,
      filter_status: null,
      filter_phone: '',
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Name', key: 'name' },
        { text: 'User Name', key: 'username' },
        { text: 'Email', key: 'email' },
        { text: 'Phone', key: 'phone' },
        { text: 'Date Of Birth', key: 'date_of_birth' },
        { text: 'Passport', key: 'passport' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        deleted_at: '',
        name: '',
        username: '',
        email: '',
        avatar_url: '',
        phone: '',
        street: '',
        ward: '',
        district: '',
        province: '',
        country: '',
        status: '',
        gg_uid: '',
        fb_uid: '',
        sms_verified: '',
        email_verified: '',
        verify_token: '',
        generated_token_at: '',
        apple_uid: '',
        id_number: '',
        id_card_front: '',
        id_card_back: '',
        transaction_noty: '',
        address: '',
        date_of_birth: '',
        gender: '',
        invite_code: '',
        invited_by_id: '',
        register_platform: '',
        passport: '',
        metadata: ''
      },
      metadata: {},
      receiver_info: {},
      save_receiver: '',
      dialog: false,
      dialogUpdate: false,
      editedIndex: -1,
      editorConfig: {
        language: 'de'
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return this.errors.has('Status') || this.filter_status === null;
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Account List' }]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const account = ApiService.get(
        'list-account',
        `?phone=${this.filter_phone || ''}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      );
      axios
        .all([account])
        .then(
          axios.spread((...response) => {
            const accountRes = response[0];
            this.items = accountRes.data.data.accountList.map((ads) => {
              return {
                ...ads,
                date_of_birth: formatDateV2(ads.date_of_birth)
              };
            });
            this.rows = accountRes.data.data.total;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    viewItem: function (item) {
      this.dialog = true;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.metadata = JSON.parse(this.editedItem.metadata);
      this.save_receiver = this.metadata.save_receiver;
      this.receiver_info = this.metadata.receiver_info;
      this.modalTitle = `Username: ${this.editedItem.username}`;
      this.filter_status = item.status;
    },
    editItem: function (item) {
      this.dialogUpdate = true;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.modalTitle = `Update Account: ${this.editedItem.username}`;
      this.filter_status = item.status;
    },
    close() {
      this.dialog = false;
      this.dialogUpdate = false;
      this.editedItem = {
        id: '',
        deleted_at: '',
        name: '',
        username: '',
        email: '',
        avatar_url: '',
        phone: '',
        street: '',
        ward: '',
        district: '',
        province: '',
        country: '',
        status: '',
        gg_uid: '',
        fb_uid: '',
        sms_verified: '',
        email_verified: '',
        verify_token: '',
        generated_token_at: '',
        apple_uid: '',
        id_number: '',
        id_card_front: '',
        id_card_back: '',
        transaction_noty: '',
        address: '',
        date_of_birth: '',
        gender: '',
        invite_code: '',
        invited_by_id: '',
        register_platform: '',
        passport: '',
        metadata: ''
      };
      this.metadata = {};
      this.receiver_info = {};
      this.save_receiver = '';
      this.editedIndex = -1;
      this.filter_phone = null;
      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      this.editedItem.status = this.filter_status;
      let accountData = {
        status: this.editedItem.status
      };
      if (this.editedIndex > -1) {
        // Update account
        axios({
          method: 'PUT',
          url: `accounts/${this.items[this.editedIndex].id}`,
          data: accountData
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Accounts updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast(`errors`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    }
  }
};
</script>

<style lang="scss">
.metadata {
  font-size: 15px;
  color: #666666;
}
</style>
